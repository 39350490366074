<template>
  <div >
      <group v-for="(group, index) in groups" :key="index"
          :group="group"
      />
  </div>
</template>

<script>
import Group from "@/components/baseGroups/group";

export default {
  name: "groupList",
  components: {Group},
  props: ['groups'],
  data() {
    return {
      loading: false
    }
  },
}
</script>

<style lang="scss">/*

*/</style>