<template>
    <div>
        <div v-if="!isLoading">
            <div v-if="typeof users !== 'undefined' && users.length" class="list-group">
                <router-link v-for="userItem in users" :key="userItem.id" :to="{name: 'UserEdit', params: {id: userItem.id}}" class="list-group-item">
                    {{userItem.login}} ({{userItem.first_name}} {{userItem.last_name}})
                </router-link>
            </div>
        </div>
      <div v-else>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
        <b-skeleton width="85%"></b-skeleton>
        <b-skeleton width="55%"></b-skeleton>
        <b-skeleton width="70%"></b-skeleton>
      </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
    export default {
        data() {
            return {
                isLoading: false,
            };
        },
        computed: {
            ...mapGetters('users', ['users']),
        },
        methods: {
            loadUsers() {
                this.isLoading = true;
                this.$store.dispatch('users/getUsersList',{
                    filters: {
                        has_source_group: false,
                        parent_or_self: JSON.parse(localStorage.getItem('user')).id
                    },
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        mounted() {
            this.$store.dispatch('users/clearUsersList');
            this.loadUsers();
        }
    }
</script>
<style lang="scss">/*
    .card {
        min-width: 400px;
        min-height: 400px;
    }
    h3 {
        font-weight: 700;
    }
*/</style>
